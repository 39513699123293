import React, { ReactElement, useEffect, useState } from 'react'
import { Breakpoint, Theme } from '@mui/material'
import { YouTubeEvent } from 'react-youtube'

import Section from '@components/core/container/Section'
import YouTubePlayer from '@components/core/media/YoutubePlayer'
import useGlobalState from '@components/site/hooks/useGlobalState'
import useLightbox from '@components/site/hooks/useLightbox'
import DialogYoutubeConsent from '@components/core/dialog/DialogYoutubeConsent'
import Headline from '@components/core/text/Headline'
import Copy from '@components/core/text/Copy'

export type VideoProps = {
  youtubeId: string
  headline: string
  copy?: MCDC.Props.RichText
  image?: MCDC.Props.IGlobalImage
  maxWidth?: Breakpoint
  sx?: MCDC.Props.IDefault['sx']
  compact?: boolean
  customPaddingX?: number
  onPlaying?: ({ target }: YouTubeEvent) => void
  customStyle?: boolean
}

export default function Video({
  youtubeId,
  headline,
  copy,
  image,
  maxWidth,
  sx,
  compact,
  customPaddingX,
  onPlaying,
  customStyle = false,
}: VideoProps): ReactElement {
  const { showDialog } = useLightbox()
  const { isYoutubeConsented, setYoutubeConsented } = useGlobalState()
  const [isLocalConsented, setIsLocalConsented] = useState(false)
  const [isClicked, setIsClicked] = useState(false)

  useEffect(() => {
    setIsLocalConsented(isYoutubeConsented)
  }, [isYoutubeConsented])

  const renderBaseComponent = (customStyles: MCDC.Props.IDefault['sx']) => {
    return (
      <YouTubePlayer
        youtubeId={youtubeId}
        thumbnail={image}
        isConsented={isLocalConsented}
        isAutoPlay={isLocalConsented && isClicked}
        rounded={compact ? true : false}
        description={compact ? headline : undefined}
        onClick={() => {
          if (!isYoutubeConsented) {
            let isConsent = false
            showDialog({
              id: 'youtubeConsentDialog',
              props: {
                onClick: () => {
                  setYoutubeConsented(isConsent)
                  setIsClicked(true)
                  setIsLocalConsented(true)
                },
                onConsent(value) {
                  isConsent = value
                },
              },
              component: DialogYoutubeConsent,
            })
          } else {
            setIsClicked(true)
          }
        }}
        sx={customStyles}
        onPlaying={onPlaying}
      />
    )
  }

  const renderModuleDefaultStyle = () => {
    return (
      <Section
        paddingX={customPaddingX ? customPaddingX : null}
        sx={sx}
        maxWidth={maxWidth}
      >
        {!compact && (
          <Headline variant="h2" sx={{ textAlign: 'center' }}>
            {headline}
          </Headline>
        )}
        {Copy && !compact && (
          <Copy
            component="div"
            sx={(theme: Theme) => ({
              mt: 4,
              textAlign: 'center',
              [theme.breakpoints.up('md')]: { mt: 5 },
            })}
          >
            {copy}
          </Copy>
        )}

        {renderBaseComponent((theme: Theme) => ({
          mt: 8,
          [theme.breakpoints.up('md')]: { mt: 10 },
        }))}
      </Section>
    )
  }

  return customStyle ? renderBaseComponent(sx) : renderModuleDefaultStyle()
}
