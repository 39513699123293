import React, { ReactElement } from 'react'
import Section from '@components/core/container/Section'
import Box from '@mui/material/Box'
import { Theme } from '@mui/material'

import Headline from '@components/core/text/Headline'
import Button from '@components/core/input/Button'
import SpeechbubbleSvg from '@static/images/icons/speechbubble.svg'
import { FormattedMessage } from 'react-intl'
import useChatbot from '@components/site/hooks/chatbot/useChatbot'
import { kEventActionChatbotModule } from '@utils/constants'

export type ChatbotProps = {
  sx?: MCDC.Props.IDefault['sx']
}

export default function Chatbot({ sx }: ChatbotProps): ReactElement {
  const { openChatbot } = useChatbot()
  return (
    <Section
      theme="primary"
      paddingSize="small"
      withDrip={true}
      sx={[sx, { color: 'text.inverted', textAlign: 'center' }]}
    >
      <Box sx={{ mb: 8 }}>
        <SpeechbubbleSvg />
      </Box>
      <Headline variant="h3" component="span" sx={{ mb: 2 }}>
        <FormattedMessage id="chatbot.headline" />
      </Headline>
      <Button
        variant="text"
        onClick={() => {
          openChatbot(kEventActionChatbotModule)
        }}
        color="secondary"
        sx={(theme: Theme) => ({
          mt: 6,
          mb: 3,
          [theme.breakpoints.up('lg')]: {
            mt: 12,
          },
        })}
      >
        <FormattedMessage id="chatbot.label" />
      </Button>
    </Section>
  )
}
