import React, { ReactElement } from 'react'

import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'

import theme from '@config/theme'
import LightboxProvider from '@components/site/providers/LightboxProvider'
import StateProvider from '@components/site/providers/StateProvider'
import SearchProvider from '@components/site/providers/SearchProvider'
import { HistoryLocation } from '@gatsbyjs/reach-router'
import { QueryParamProvider } from 'use-query-params'
import { ReachAdapter } from 'use-query-params/adapters/reach'

import SnackbarProvider from './SnackbarProvider'
import StaticContentfulProvider from './StaticContentfulProvider'
import useContentfulPageJobOffers from '@components/site/hooks/contentful/useContentfulPageJobOffers'
import useApiMeta from '../hooks/api/useApiMeta'
import useContentfulPageJobDetail from '../hooks/contentful/useContentfulPageJobDetail'
import useContentfulGlobalFaq from '../hooks/contentful/useContentfulGlobalFaq'
import ChatbotProvider from './ChatbotProvider'
import useContentfulPageJobProfile from '../hooks/contentful/useContentfulPageJobProfile'
import QueryParamsProvider from './QueryParamsProvider'

type ProvidersProps = {
  children: MCDC.Props.IDefault['children']
  pageContext?: MCDC.Contentful.IPageContext
  location?: HistoryLocation
  locale?: string
}

export default function Providers({
  children,
  location,
  locale,
}: ProvidersProps): ReactElement {
  const [offersPage, offersPages] = useContentfulPageJobOffers()
  const pagesJobDetail = useContentfulPageJobDetail()
  const pagesJobProfile = useContentfulPageJobProfile()
  const globalFaqs = useContentfulGlobalFaq()
  const apiMeta = useApiMeta(true)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryParamProvider adapter={ReachAdapter}>
        <StaticContentfulProvider
          pagesJobOffers={offersPages}
          pagesJobDetail={pagesJobDetail}
          pagesJobProfile={pagesJobProfile}
          globalFaqs={globalFaqs}
          meta={apiMeta}
        >
          <ChatbotProvider
            location={location}
            linkToSearch={offersPage?.fields.linkTo}
          >
            <SnackbarProvider>
              <StateProvider location={location} locale={locale}>
                <QueryParamsProvider>
                  <SearchProvider
                    location={location}
                    linkToSearch={offersPage?.fields.linkTo}
                    meta={apiMeta}
                  >
                    <LightboxProvider>{children}</LightboxProvider>
                  </SearchProvider>
                </QueryParamsProvider>
              </StateProvider>
            </SnackbarProvider>
          </ChatbotProvider>
        </StaticContentfulProvider>
      </QueryParamProvider>
    </ThemeProvider>
  )
}
